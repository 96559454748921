import React, { Fragment } from "react";
import { Modal, Grid, Row, Col, Image } from "react-bootstrap";
import BaseComponent from "../../utils/BaseComponent";
import { CopyToClipboard } from "react-copy-to-clipboard";

import close_icon from "../../assets/images/icons/close.svg";
import active_tick from "../../assets/images/icons/active-tick.svg";
import inactive_tick from "../../assets/images/icons/inactive.svg";
import facebook_icon from "../../assets/images/icons/facebook.svg";
import instagram_icon from "../../assets/images/icons/insta.svg";
import twitter_icon from "../../assets/images/icons/twitter.svg";
import GAUtils from "../../utils/GAUtils";

// import demo from "../../assets/images/demo-images/demo1.jpg";

class ContentModal extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isImageLandscape: this.props.isImageLandscape,
      btnText: "Copy",
      isCopied: false,
      isShareOnly: false,
      isHashtagOnly: this.props.contentData.caption === "" ? true : false,
      caption: this.props.contentData.caption,
      hashtag: this.props.contentData.hashtag,
      newLine: "\n\n\n",
      captionSelected: true,
      hashtagSelected: true
    };
  }

  handleBtnText = () => {
    this.setState({
      btnText: "Copied!",
      isCopied: true
    });
    setTimeout(() => {
      this.setState({
        btnText: "Copy",
        isCopied: false
      });
    }, 1000);

    GAUtils.handleClickEvent("Copy", "copyied from inside modal");
  };

  handleToggleCaptionSelected = () => {
    this.setState({
      caption: this.state.captionSelected ? "" : this.props.contentData.caption,
      newLine:
        !this.state.captionSelected && this.state.hashtagSelected
          ? "\n\n\n"
          : " ",
      captionSelected: !this.state.captionSelected
    });
  };

  handleToggleHashtagSelected = () => {
    this.setState({
      hashtag: this.state.hashtagSelected ? "" : this.props.contentData.hashtag,
      newLine:
        this.state.captionSelected && !this.state.hashtagSelected
          ? "\n\n\n"
          : " ",
      hashtagSelected: !this.state.hashtagSelected
    });
  };

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onClose}
        animation={false}
      >
        <Modal.Body>
          <Image
            src={close_icon}
            onClick={this.props.onClose}
            className="close-btn"
          />

          <Grid className="modal-container">
            <Row>
              <Col className="mg-t-1 mg-b-2">
                <div className="top align-center">
                  <div className="profile-image-container mg-auto">
                    {/* <Image src={demo} alt="profile" */}
                    <Image
                      src={this.props.profileImage}
                      alt="profile"
                      onLoad={this.props.onImgLoad}
                      className={this.props.isImageLandscape ? "" : "portrait"}
                    // className="portrait"
                    />
                  </div>
                </div>
              </Col>

              <Col md={12} className="mg-b-2">
                <div className="middle">
                  <h3>Hashtags</h3>

                  {this.state.isHashtagOnly ? (
                    <div className="content">
                      <p className="border full-width">
                        {this.props.contentData.hashtag}
                      </p>
                    </div>
                  ) : (
                      <div className="content">
                        <p className="border">{this.props.contentData.hashtag}</p>

                        <Image
                          src={
                            this.state.hashtagSelected
                              ? active_tick
                              : inactive_tick
                          }
                          alt="Tick icon"
                          onClick={this.handleToggleHashtagSelected}
                        />
                      </div>
                    )}
                  {this.props.contentData.caption === "" ? (
                    ""
                  ) : (
                      <Fragment>
                        <h3>Captions</h3>
                        <div className="content">
                          <p className="border">
                            {this.props.contentData.caption}
                          </p>

                          <Image
                            src={
                              this.state.captionSelected
                                ? active_tick
                                : inactive_tick
                            }
                            alt="Tick icon"
                            onClick={this.handleToggleCaptionSelected}
                          />
                        </div>
                      </Fragment>
                    )}
                </div>
              </Col>

              <Col md={12} className="mg-t-2 mg-b-2">
                <div className="bottom align-center">
                  <Fragment>
                    {!this.state.captionSelected &&
                      !this.state.hashtagSelected ? (
                        <span className="modal-copy-btn disable">
                          {this.state.btnText}
                        </span>
                      ) : (
                        <CopyToClipboard
                          text={
                            this.state.caption && this.state.hashtag !== "" ?
                              this.state.caption +
                              this.state.newLine +
                              this.state.hashtag
                              :
                              this.state.hashtag !== "" && this.state.caption === "" ?
                                this.state.hashtag
                                :
                                this.state.caption
                          }
                        >
                          <span
                            className={
                              this.state.isCopied
                                ? "modal-copy-btn copied-btn"
                                : "modal-copy-btn"
                            }
                            onClick={this.handleBtnText}
                          >
                            {this.state.btnText}
                          </span>
                        </CopyToClipboard>
                      )}

                    <span className="light-text mg-r-2 mg-l-2">or</span>
                  </Fragment>
                  {/* } */}

                  {!this.state.captionSelected &&
                    !this.state.hashtagSelected ? (
                      <Fragment>
                        <a
                          // href="javascript:;"
                          href="/"
                          onClick={e => {
                            e.preventDefault();
                          }}
                          className="mg-r-1 disable"
                        >
                          <Image src={instagram_icon} alt="instagram icon" />
                        </a>

                        <a
                          // href="/"
                          // href="javascript:;"
                          href="/"
                          onClick={e => {
                            e.preventDefault();
                          }}
                          className="mg-r-1 disable"
                        >
                          <Image src={facebook_icon} alt="facebook icon" />
                        </a>

                        <a
                          // href="/"
                          href="/"
                          onClick={e => {
                            e.preventDefault();
                          }}
                          className="disable"
                        >
                          <Image src={twitter_icon} alt="instagram icon" />
                        </a>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <a
                          href="https://www.instagram.com/"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="mg-r-1"
                        >
                          <Image
                            onClick={() =>
                              GAUtils.handleClickEvent("share", "Instagram share")
                            }
                            src={instagram_icon}
                            alt="instagram icon"
                          />
                        </a>

                        <a
                          // href="/"
                          href="https://www.facebook.com"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="mg-r-1"
                        >
                          <Image
                            onClick={() =>
                              GAUtils.handleClickEvent("share", "Facebook share")
                            }
                            src={facebook_icon}
                            alt="facebook icon"
                          />
                        </a>

                        <a
                          // href="/"
                          href="https://twitter.com/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Image
                            onClick={() =>
                              GAUtils.handleClickEvent("share", "Twitter share")
                            }
                            src={twitter_icon}
                            alt="instagram icon"
                          />
                        </a>
                      </Fragment>
                    )}
                </div>
              </Col>
            </Row>
          </Grid>
        </Modal.Body>
      </Modal>
    );
  }
}

export default ContentModal;
