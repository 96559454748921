import axios from "axios";

const instance = axios.create(
    {
        // baseURL: "http://13.232.184.100/dev/api/v1/",   //dev
        baseURL: "https://api.tagwag.ai/caption/dev/",  // prod
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }
    }
);

export default instance;