import React, { Component } from 'react'
import Topbar from '../components/navbar/Topbar'
import { Panel, PanelGroup, Glyphicon } from "react-bootstrap";
import faq from '../assets/data/faq.json'

class FAQ extends Component {
	constructor(props) {
		super(props);

		this.state = {
			activeKey: '1'
		};
	}

	handleSelect = (activeKey) => {
		this.setState({ activeKey });
	}


	render() {
		return (
			<div>
				<Topbar searchBar={false} />
				<div className="faq-section">
					<div className="faq-box">
						<h2>Contact us</h2>
						<p>For any queries contact us at <a href="mailto:developer.tagwag@gmail.com">developer.tagwag@gmail.com</a> or DM us at our Instagram account <a href="https://www.instagram.com/tagwagapp/">  @tagwagapp</a></p>
						<h2>FAQ</h2>
						<PanelGroup
							accordion
							id="faq-page-panel"
							activeKey={this.state.activeKey}
							onSelect={this.handleSelect}
						>
							{
								faq.map((data) => {
									return (
										<Panel key={data.id} eventKey={data.id}>
											<Panel.Heading>
												<Panel.Title toggle>{data.question}
													{this.state.activeKey === data.id ? <Glyphicon glyph="glyphicon glyphicon-menu-up" /> : <Glyphicon glyph="glyphicon glyphicon-menu-down" />}
												</Panel.Title>
											</Panel.Heading>
											<Panel.Body collapsible>{data.answer}</Panel.Body>
										</Panel>
									)
								})
							}
						</PanelGroup>

					</div>
				</div>
			</div>
		)
	}
}

export default FAQ;
