import ReactGA from 'react-ga';

class GAUtils {

    static handleClickEvent = (category, action, value, label) => {
        ReactGA.event({
            category,
            action,
            value,
            label
        })
    }

}

export default GAUtils
