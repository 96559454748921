import React, { Component } from 'react'
import Topbar from '../components/navbar/Topbar'
import { Glyphicon } from 'react-bootstrap';

class LearnMore extends Component {
	constructor(props) {
		super(props);

		this.state = {

		};
	}

	render() {
		return (
			<div>
				<Topbar searchBar={false} />
				<div className="faq-section">
					<div className="faq-box">
						<h2>Set Up a Creator Account</h2>
						<p>You can convert your personal or business profile into a creator account to access features that make it easier to control your online presence, understand your growth and manage your messages. If your personal account is private, completing these steps will make it public. Note that all pending follow requests will be automatically accepted when you go public.</p>
						<p>To switch your personal account or business profile to a creator account:</p>
						<ol>
							<li>Go to your account and tap<Glyphicon className="icon" glyph="menu-hamburger" /></li>
							<li>Select <Glyphicon className="icon" glyph="cog" /></li>
							<li>Select <b>Account.</b></li>
							<ul>
								<li>If you are migrating from a personal account, choose <b>Switch to Professional Account</b> and tap <b>Creator.</b></li>
								<li>If you are migrating from a business profile, choose <b>Switch to Creator Account.</b></li>
							</ul>
							<li>Select your category. You can choose a category that best describes what you do.</li>
							<li>Connect to your Facebook Page</li>
							<ul>
								<li>If you have a Facebook Page you would like to link to your account, select it from the list that appears.</li>
								<li>If you do not have a Facebook Page then you should create and connect to the account.</li>
							</ul>
							<li>Review your contact information. At least one form of contact information is required. You'll have the option to display or hide this on your profile.</li>
							<li>Choose your profile display options. You can decide whether to hide or display your category and contact details on your profile.</li>
						</ol>
						<br />
						<h2>Set Up a Business Account on Instagram</h2>
						<p>On Instagram, you can convert your personal profile to a business account to access features that can help you grow your business.</p>
						<p>To switch your profile to a business account:</p>
						<ol>
							<li>Go to your profile and tap <Glyphicon className="icon" glyph="menu-hamburger" /> in the upper right corner.</li>
							<li>Tap <b><Glyphicon className="icon" glyph="cog" />Settings.</b></li>
							<li>Tap <b>Account.</b></li>
							<li>Tap <b>Switch to Professional Account.</b></li>
							<li>Tap <b>Business.</b></li>
							<li>Connect to your Facebook Page</li>
							<ol type="a">
								<li>If you have a Facebook Page you would like to link to your account, select it from the list that appears.</li>
								<li>If you do not have a Facebook Page then you should create and connect to the account.</li>
							</ol>
							<li>Add details, like your business category and contact information.</li>
							<li>Tap <b>Done.</b></li>
						</ol>
						<p>
							With a business account, you'll be able to access business features and Instagram Insights. These tools can help you understand who is engaging with your business on Instagram.
						</p>
						<p>
							You also have the option to display or hide your business category and contact information on your profile. After setting up, go to your profile and tap <b>Edit Profile.</b> Go to <b>Profile Display</b> under Public Business Information to choose whether you want to hide or display your category label and contact info. Then, tap <b>Done.</b>
						</p>
						<br />
						<hr />
						<br />
						<p className="imp-note"> <b className="note"> Note:</b> To use 'TagWag Earn' feature, your Instagram account must be connected to your Facebook Page.</p>

					</div>
				</div>
			</div>
		)
	}
}

export default LearnMore;
