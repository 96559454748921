import React from "react";
import BaseComponent from "../../utils/BaseComponent";
import { Image, Navbar } from "react-bootstrap";
import logo from "../../assets/images/header-logo.svg";
import search from "../../assets/images/search-icon.svg";
import GAUtils from "../../utils/GAUtils";

class Topbar extends BaseComponent {
	constructor(props) {
		super(props);
		this.state = {
			value: ""
		};
	}

	handleChange = (event) => {
		this.setState({ value: event.target.value });
	}

	onSubmit = (event) => {
		event.preventDefault();
		console.log('Events', this.state.value);
		GAUtils.handleClickEvent("Search", "Search from Home Page", this.state.value)
		window.location.assign(this.state.value ? 'https://hashtags-generator.com/best-hashtags-for-' + this.state.value : 'https://hashtags-generator.com/');

	}

	render() {
		return (
			<Navbar className="custom-nav navbar-fixed-top">
				<a href="/">
					<Image src={logo} />
				</a>
				{
					this.props.searchBar
						?
						<form onSubmit={this.onSubmit}>
							<input
								type="text"
								value={this.state.value}
								onChange={this.handleChange}
								placeholder="Search hashtag"
							/>
							<button type="submit" className="search"><Image src={search} /></button>
						</form>
						:
						null
				}

			</Navbar>
		);
	}
}

export default Topbar;
