import React from "react";
import { Modal } from "react-bootstrap";
import BaseComponent from "../../utils/BaseComponent";
import copySuccess from "../../assets/images/success.svg";

class CopyModal extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    setTimeout(this.props.onClose, 1000);
  };

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onClose}
        className="copy-modal"
        animation={false}
      >
        <div className="modal-bg-image">
          <h3>Copied</h3>
          <img
            src={copySuccess}
            className="copied-image"
            alt="copt img"
            onClick={this.props.onClose}
          />
        </div>
      </Modal>
    );
  }
}

export default CopyModal;
