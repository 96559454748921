import React from "react";
import { Modal, Image } from "react-bootstrap";
import BaseComponent from "../../utils/BaseComponent";
import new_error_image from "../../assets/images/something-went-wrong.png";
import close_icon from "../../assets/images/icons/close.png";

class ErrorModal extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    setTimeout(this.props.onClose, 2000);
  };

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onClose}
        className="error-modal"
        animation={false}
      >
        <Modal.Body>
          <Image
            src={close_icon}
            onClick={this.props.onClose}
            className="close-btn"
          />
          <div className="error-modal-content">
            <Image
              src={new_error_image}
              alt="error img"
              onClick={this.props.onClose}
            />
            <h3>Oops!</h3>
            <h4> Something went wrong! </h4>
            <h4> Don’t worry its not you but us. </h4>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default ErrorModal;
