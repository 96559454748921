import React, { Component } from 'react';
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import ReactGA from 'react-ga';
import "./assets/scss/style.scss";
import Home from "./pages/Home"
// import HomeAfter from "./pages/HomeAfter"
import Test from "./pages/Test"
import GAUtils from './utils/GAUtils';
import FAQ from './pages/FAQ';
import LearnMore from './pages/LearnMode';

ReactGA.initialize('UA-137732092-1');

class App extends Component {
	componentDidMount = () => {
		window.addEventListener('appinstalled', evt => {
			console.log('App Installed');
			GAUtils.handleClickEvent("Add to home page", "Progressive web app is added to home.")
		});
	}
	render() {
		return (
			<BrowserRouter>
				<Switch>
					<Route exact path="/" component={Home} />
					<Route exact path="/test" component={Test} />
					<Route exact path="/help" component={FAQ} />
					<Route exact path="/learn-more" component={LearnMore} />
					<Redirect to="/" />

					{/* <Route exact path="/after" component={HomeAfter} /> */}
				</Switch>
			</BrowserRouter>
		);
	}
}

export default App;