import React, { Fragment, useMemo } from "react";
//import ReactGA from 'react-ga';
import { Image } from "react-bootstrap";
import BaseComponent from "../utils/BaseComponent";
import instance from "../utils/Axios";
import { CopyModal, ErrorModal, ContentModal } from '../components/modals';
import Topbar from "../components/navbar/Topbar";
import { CopyToClipboard } from 'react-copy-to-clipboard';
//import { isMobile, isAndroid, isIOS } from 'react-device-detect';
import app_store from "../assets/images/appstore.svg";
import google_play from "../assets/images/playstore.svg";
import mockup from "../assets/images/mob1.svg";
import copy_icon from "../assets/images/icons/copy.svg";
import camera_image from "../assets/images/camera.svg";
import drag_image from "../assets/images/drag.png";
import { useDropzone } from 'react-dropzone'
import GAUtils from "../utils/GAUtils";
import MobileHome from '../components/mobile/Home';
import Compressor from 'compressorjs';

class Home extends BaseComponent {
	constructor(props) {
		super(props);
		this.state = {
			width: 0,
			height: 0,
			landingToDetail: true,
			isLandingPage: true,
			isDataPresent: false,
			profileImage: "",
			profileImageLoading: false,
			droping: false,
			copyModal: false,
			errorModal: false,
			contentModal: false,
			isImageLandscape: true,
			contentModalData: {
				caption: "",
				hashtag: ""
			},
			captions: [],
			hashtag: "",
			imageId: 0,
			apiError: {
				isError: false,
				data: ""
			}
		};
	}

	componentDidMount = () => {
		window.onpopstate = () => {
			//window.location.replace('/');
			this.props.history.go('/');
		}
        /*
                this.updateWindowDimensions();
                window.addEventListener('resize', this.updateWindowDimensions); */


        /*  ReactGA.initialize('UA-137732092-1');
         ReactGA.pageview('/Homepage');

         if (isMobile && isAndroid) {
             window.location.replace('https://play.google.com/store/apps/details?id=ai.tagwag.caption');
         }
         if (isMobile && isIOS) {
             window.location.replace('https://itunes.apple.com/tt/app/tagwag-captions-for-instagram/id1453977874');
         } */
	}

	onImgLoad = ({ target: img }) => {
		if (img.offsetHeight >= img.offsetWidth) {
			// console.log("portrate");
			this.setState({
				isImageLandscape: false
			})
		}
		else {
			// console.log("landscape");
			this.setState({
				isImageLandscape: true
			})
		}
	}

	getData = (file) => {
		console.log('in getData');
		this.setState({
			isDataPresent: false
		})

		const formData = new FormData();

		formData.append('file', file);
		formData.append('device_id', 1);

		instance.post("test", formData)
			// instance.post("test", formData)
			.then(response => {
				const url = this.setParams({ id: response.data.data.id });
				//do not forget the "?" !
				this.props.history.push(`?${url}`);


				const formattedcaptions = [];
				let data = response.data.data.caption;

				for (let i = 0; i < data.length; i++) {
					let caption = this.removeChar(data[i]);
					formattedcaptions.push(caption);
				}

				this.setState({
					captions: formattedcaptions,
					hashtag: response.data.data.hashtag,
					apiError: {
						isError: response.data.error,
						data: response.data.message
					},
					imageId: response.data.data.id,
					isLandingPage: false,
					profileImageLoading: false
				});

				if (this.state.landingToDetail) {
					setTimeout(() => {
						this.setState({
							isDataPresent: true,
							landingToDetail: false
						})
					}
						, 1200);
				}
				else {
					this.setState({
						isDataPresent: true
					})
				}
			})
			.catch(error => {
				console.log("err ", error);
				console.log("Something went wrong in get-data API");
				this.handleClickLandingPageLink();
				this.setState({
					errorModal: true,
					isDataPresent: true,
					apiError: {
						isError: true,
						data: "Something went wrong"
					}
				});
			})
	}

	setParams = ({ id = "" }) => {
		const searchParams = new URLSearchParams();
		searchParams.set("id", id);
		return searchParams.toString();
	}

	removeChar = text => {
		text = text.trim();
		return text.replace(/[&\\#,+()$~%.'":*?<>{}]n/g, '');
	}

	handleTogglePage = () => {
		let toggle = this.state.isLandingPage;
		this.setState({
			isLandingPage: !toggle
		});
	}

	handleDropingTrue = () => {
		// this.setState({
		//     droping: true,
		// })
	}

	handleDropingFalse = () => {
		// this.setState({
		//     droping: false,
		// })
	}

	handleClickLandingPageLink = () => {
		this.setState({
			landingToDetail: true,
			isLandingPage: true,
			isDataPresent: false,
			profileImage: "",
			profileImageLoading: false,
			droping: false,
			copyModal: false,
			errorModal: false,
			contentModal: false,
			contentModalData: {
				caption: "",
				hashtag: ""
			},
			captions: [],
			hashtag: "",
			apiError: {
				isError: false,
				data: ""
			}
		});
	}

	openCopyModal = (e, id) => {
		e.stopPropagation();
		if (id === 1) {
			GAUtils.handleClickEvent("Copy", "hashtags copied from outside modal")
		}
		else if (id === 2) {
			GAUtils.handleClickEvent("copy", "captions copied from outside modal")
		}
		this.setState({
			copyModal: !this.state.copyModal,
		});
	};

	openErrorModal = () => {
		this.setState({
			errorModal: true
		});
	};

	closeErrorModal = () => {
		this.setState({
			errorModal: false
		});
	};

	closeCopyModal = () => {
		this.setState({
			copyModal: false
		});
	};

	openContentModal = () => {
		this.setState({
			contentModal: true
		});
	};

	closeContentModal = () => {
		this.setState({
			contentModal: false
		});
	};

	handleShareClick = e => {
		e.stopPropagation();
		// console.log("share button clicked");
	}

	handleUploadClick = (file) => {
		console.log(' URL.createObjectURL(file)', URL.createObjectURL(file));
		this.setState({
			profileImage: URL.createObjectURL(file),
			profileImageLoading: true
		});
		this.compress(file);
		//this.getData(file);
	};

	compress = (file) => {
		if (!file) {
			return;
		}
		var self = this;
		new Compressor(file, {
			quality: 0.9,
			success(result) {
				console.log('Success Compression', result);
				self.getData(result);
			},
			error(err) {
				console.log(err.message);
			},
		});
	}

	handleContentModal = (hashtag, caption) => {
		this.setState({
			contentModalData: {
				caption: caption,
				hashtag: hashtag
			},
		});
		this.openContentModal();
	}

	render() {

		let width = window.innerWidth;
		if (width < 500) {
			return (
				<MobileHome
					isLandingPage={this.state.isLandingPage}
					onImgLoad={this.onImgLoad}
					handleUploadClick={this.handleUploadClick}
					profileImage={this.state.profileImage}
					profileImageLoading={this.state.profileImageLoading}
					isDataPresent={this.state.isDataPresent}
					isImageLandscape={this.state.isImageLandscape}
					hashtag={this.state.hashtag}
					captions={this.state.captions}
					imageId={this.state.imageId}
					errorModal={this.state.errorModal}

				/>
			);
		} else {
			let bg = ""
			if (this.state.profileImage === "") {
				// bg = "linear-gradient(black, black), url(" + require("../assets/images/clipboard-image.png") + ")"
				bg = "url(" + require("../assets/images/cover-image.jpg") + ")"
			} else {
				bg = "url(" + this.state.profileImage + ")"
			}

			let mobileBackgroundStyle = {
				backgroundImage: bg
			};

			const baseStyle = {
				position: "absolute",
				width: "inherit",
				height: "inherit",
				borderRadius: 0,
				zIndex: 6,
				// opacity: 0.5,
				// backgroundColor: '#fff'
			}

			const activeStyle = {
				// backgroundColor: '#999',
				opacity: 0
			}

			const acceptStyle = {
				// backgroundColor: '#00e676'
			}

			const rejectStyle = {
				// backgroundColor: '#ff1744'
			}

			function FileUploadBotton(props) {
				const {
					getRootProps,
					getInputProps,
					isDragActive,
					isDragAccept,
					isDragReject,
					open
				} = useDropzone({
					accept: 'image/*',
					multiple: false,
					onDrop: acceptedFiles => {
						if (acceptedFiles.length > 0) {
							props.handleUploadClick(acceptedFiles[0]);
						}
					}
				})


				const style = useMemo(() => ({
					...baseStyle,
					...(isDragActive ? activeStyle : {}),
					...(isDragAccept ? acceptStyle : {}),
					...(isDragReject ? rejectStyle : {})
				}), [
					isDragActive,
					isDragReject,
					isDragAccept
				])

				const rootProps = getRootProps({
					// Disable click and keydown behavior
					onClick: event => event.stopPropagation(),
					onKeyDown: event => {
						if (event.keyCode === 32 || event.keyCode === 13) {
							event.stopPropagation()
						}
					}
				})

				return (
					<div {...rootProps} style={style}>
						<input {...getInputProps()} />
						<button
							type="button"
							onClick={(e) => {
								GAUtils.handleClickEvent("Upload", "New Image Upload")
								open()
							}}
							className="change-image-btn">
							CHANGE IMAGE
                    </button>
					</div>
				)
			}

			function FileUpload(props) {
				const {
					getRootProps,
					getInputProps
				} = useDropzone({
					accept: 'image/*',
					multiple: false,
					onDrop: acceptedFiles => {
						if (acceptedFiles.length > 0) {
							props.handleUploadClick(acceptedFiles[0]);

						}
					}
				})

				return (
					<div {...getRootProps()} className="file-upload-area">
						<input {...getInputProps()} />
						<p></p>
					</div>
				)
			}

			return (
                /* isMobile && isAndroid ?
                    <div className="align-center-of-display">
                        <a href="https://play.google.com/store/apps/details?id=ai.tagwag.caption" target="_blank" rel="noopener noreferrer"
                        >
                            <Image src={google_play} className="download-button" alt="play store" />
                        </a>
                    </div>
                    :
                    isMobile && isIOS ?
                        <div className="align-center-of-display">
                            <a href="https://itunes.apple.com/tt/app/tagwag-captions-for-instagram/id1453977874"
                                target="_blank" rel="noopener noreferrer"
                            >
                                <Image src={app_store} className="download-button app-store-button" alt="app store" />
                            </a>
                        </div>
                        : */
				// <div className="align-center-of-display">
				//    /*  <a href="https://play.google.com/store/apps/details?id=ai.tagwag.caption" target="_blank" rel="noopener noreferrer"
				//     >
				//         <Image src={google_play} className="download-button" alt="play store" />
				//     </a> */
				//     <h3>HEyyyyyyy</h3>
				// </div>
				<Fragment>
					{
						this.state.contentModal &&
						<ContentModal
							onImgLoad={this.state.onImgLoad}
							isImageLandscape={this.state.isImageLandscape}
							contentData={this.state.contentModalData}
							profileImage={this.state.profileImage}
							show={this.state.contentModal}
							onClose={this.closeContentModal}
						/>
					}
					{
						this.state.copyModal &&
						<CopyModal
							//image_link={this.state.copyModalImage}
							show={this.state.copyModal}
							onClose={this.closeCopyModal}
						/>
					}
					{
						this.state.errorModal &&
						<ErrorModal
							show={this.state.errorModal}
							onClose={this.closeErrorModal}
						/>
					}

					<div className={this.state.isLandingPage ? "wrapper before" : "wrapper after"}>
						<Topbar searchBar={true} />
						<div style={mobileBackgroundStyle} className="left-section overlay">
							<div className="left-inner-box">
								<div className="mobile-mockup">
									<Image src={mockup} className="mobile-mockup-frame" alt="mockup" />
									{<div className="mobile-mockup-image-section">
										{
											this.state.isLandingPage ?
												<Fragment>
													{
														this.state.droping ?
															<Fragment>
																<span className="top-text-content">
																	<h3 className="heading">Drag & Drop</h3>
																	<p className="content">image here</p>
																</span>
																<span className="center-image dashed-border">
																	<Image src={drag_image} />
																</span>
																<span className="bottom-text-content">
																	CLICK OR DRAG <br />TO UPLOAD IMAGE
                                                                </span>
															</Fragment>
															:
															this.state.profileImageLoading ?
																<Fragment>
																	<span className="center-image solid-border" >
																		<Image src={this.state.profileImage}
																			onLoad={this.onImgLoad}
																			className={this.state.isImageLandscape ? "" : "portrait"}
																		/>
																	</span>
																	<span className="bottom-text-content">
																		<span className="bold-text">Sit tight!</span>
																		<span className="normal-text">We are finding hashtags & captions for your image.</span>
																	</span>
																</Fragment>
																:
																<Fragment>
																	<span className="center-image dashed-border">
																		<Image src={camera_image} />
																	</span>
																	<span className="bottom-text-content">CLICK OR DRAG TO UPLOAD IMAGE</span>
																</Fragment>
													}
													<span className="ripple r1"></span>
													<span className="ripple r2"></span>
													<span className="ripple r3"></span>
													<span className="ripple r4"></span>
													<span className="ripple r5"></span>
													<span className="ripple r6"></span>

													<FileUpload
														handleUploadClick={(file) => {
															GAUtils.handleClickEvent("Image Upload", "Upload Image")
															this.handleUploadClick(file)
														}}
														handleDropingTrue={this.handleDropingTrue}
													/>
												</Fragment>
												:
												this.state.profileImageLoading ?
													<Fragment>
														<span className="center-image solid-border">
															<Image src={this.state.profileImage}
																onLoad={this.onImgLoad}
																className={this.state.isImageLandscape ? "" : "portrait"}
															/>
														</span>
														<span className="bottom-text-content">
															<span className="bold-text">Sit tight</span>
															<span className="normal-text">We are finding hashtag & captions for your image</span>
															{/* <span className="normal-text">& captions for your image</span> */}
														</span>

														<span className="ripple r1"></span>
														<span className="ripple r2"></span>
														<span className="ripple r3"></span>
														<span className="ripple r4"></span>
														<span className="ripple r5"></span>
														<span className="ripple r6"></span>
													</Fragment>
													:
													<Fragment>
														<Image
															src={this.state.profileImage}
															alt="clipboard"
															className="clipboard-image"
														// className="portrait"
														/>
														{
															this.state.isDataPresent ?
																<FileUploadBotton handleUploadClick={this.handleUploadClick} /> : ""
														}

													</Fragment>
										}
									</div>
									}
								</div>
							</div>
						</div>

						<div className="right-section">
							{
								this.state.isLandingPage ?
									<div className={this.state.profileImageLoading ? "right-inner-box fade" : "right-inner-box"}>
										<h1>
											<p className="normal-text">Find trending </p>
											<p className="heading-text">Hashtags & Captions</p>
											<p className="normal-text">for your image</p>
										</h1>
										<div className="downloading-section">
											<a href="https://play.google.com/store/apps/details?id=ai.tagwag.caption&referrer=utm_source%3Dtagwag_website%26utm_medium%3DDownloadFromFooterPlayStore" target="_blank" rel="noopener noreferrer">
												<Image onClick={() => GAUtils.handleClickEvent("Download", "Playstore Button Clicked From Outside")} src={google_play} className="download-button" alt="play store" />
											</a>

											<a href="https://apps.apple.com/tt/app/tagwag-captions-for-instagram/id1453977874?utm_source=tagwag_home_page_website&utm_medium=DownloadAppFromIcon&utm_campaign=DownloadApp" target="_blank" rel="noopener noreferrer">
												<Image onClick={() => GAUtils.handleClickEvent("Download", "Appstore Button Clicked From Outside")} src={app_store} className="download-button app-store-button" alt="app store" />
											</a>
										</div>
									</div>
									:
									<div className={this.state.landingToDetail ? "right-inner-box fade" : "right-inner-box"}>

										{this.state.isDataPresent ?
											<Fragment>
												<div className="hashtag-section">
													<h2 className="headding">Hashtags</h2>

													<div className="content hashtag-content">
														<h4 className="inner-section border"
															onClick={() => { this.handleContentModal(this.state.hashtag, "") }}
														>
															<span className="left">
																{this.state.hashtag}
															</span>

															<span className="right">
																<CopyToClipboard
																	text={this.state.hashtag}
																>
																	<Image
																		src={copy_icon}
																		alt="Copy icon"
																		className="mg-l-1"
																		onClick={(e) => { this.openCopyModal(e, 1) }}
																	/>
																</CopyToClipboard>
															</span>
														</h4>
													</div>
												</div>

												<div className="caption-section">
													<h2 className="headding">Captions </h2>
													<div className="caption-content content">
														{this.state.captions.map((caption, key) => (
															<p className="inner-section border" key={key}
																onClick={() => {
																	this.handleContentModal(this.state.hashtag, caption)
																	GAUtils.handleClickEvent("click", "caption clicked", `position: ${key + 1}`)
																}}
															>
																<span className="left">{caption}</span>
																<span className="right">
																	<CopyToClipboard
																		text={caption}
																	>
																		<Image
																			src={copy_icon}
																			alt="Copy icon"
																			className="mg-l-1"
																			onClick={(e) => { this.openCopyModal(e, 2) }}
																		/>
																	</CopyToClipboard>
																</span>
															</p>
														))}
													</div>
												</div>
											</Fragment>
											:
											<Fragment>
												<div className="shimmer">

													<div className="animated">

														<div className="title"></div>
														<div className="padding"></div>

														<div className="description"></div>
														<div className="padding"></div>

														<div className="description-half"></div>
														<div className="padding"></div>

														<div className="padding"></div>

														<div className="padding"></div>
														<div className="padding"></div>

													</div>

													<div className="animated">

														<div className="title"></div>
														<div className="padding"></div>

														<div className="description"></div>
														<div className="padding"></div>

														<div className="description-half"></div>
														<div className="padding"></div>
														<div className="padding"></div>

														<div className="description"></div>
														<div className="padding"></div>

														<div className="description-half"></div>
														<div className="padding"></div>
														<div className="padding"></div>

														<div className="description"></div>
														<div className="padding"></div>

														<div className="description-half"></div>
														<div className="padding"></div>
														<div className="padding"></div>

														<div className="description"></div>
														<div className="padding"></div>

														<div className="description-half"></div>
														<div className="padding"></div>
														<div className="padding"></div>

													</div>
												</div>
											</Fragment>

										}

										<div className="downloading-section">
											<a
												href="https://play.google.com/store/apps/details?id=ai.tagwag.caption&referrer=utm_source%3Dtagwag_website%26utm_medium%3DDownloadFromFooterPlayStore"
												target="_blank" rel="noopener noreferrer"
											>
												<Image onClick={() => GAUtils.handleClickEvent("Download", "Playstore Button Clicked From Inside")} src={google_play} className="download-button" alt="play store" />
											</a>

											<a
												href="https://apps.apple.com/tt/app/tagwag-captions-for-instagram/id1453977874?utm_source=tagwag_home_page_website&utm_medium=DownloadAppFromIcon&utm_campaign=DownloadApp"
												target="_blank" rel="noopener noreferrer"
											>
												<Image onClick={() => GAUtils.handleClickEvent("Download", "Appstore Button Clicked From Inside")} src={app_store} className="download-button app-store-button" alt="app store" />
											</a>
										</div>

									</div>
							}
						</div>
					</div>
				</Fragment>
			);
		}
	}
}

export default Home;
