import React from "react";
import { Image, Navbar } from "react-bootstrap";
import logo from "../../assets/images/header-logo.svg";
import search from "../../assets/images/search-mob.svg";
import back from '../../assets/images/mob/back.svg';
import GAUtils from "../../utils/GAUtils";

const onSubmit = () => {
    window.location.assign('https://hashtags-generator.com/');
}

const Header = (props) => {
    return (
        <>
            {
                props.toggle ?
                    <Navbar className="mobile-nav navbar-fixed-top">
                        <a href="/">
                            <Image src={logo} />
                        </a>
                        <Image className="search-icon" src={search} onClick={(e) => {
                            GAUtils.handleClickEvent("Search", "Search From HomePage Mobile");
                            onSubmit()
                        }} />
                    </Navbar>
                    :
                    props.scrolling ?
                        <Navbar className="output-nav navbar-fixed-top">
                            <a href="/">
                                <Image className="back-button" src={back} />
                            </a>
                            <h3>Hashtags & Captions</h3>
                        </Navbar>
                        :
                        <div className="output-navbar">
                            <a href="/">
                                <Image className="back-button" src={back} />
                            </a>
                        </div>
            }
        </>
    );
}
//}

export default Header;
