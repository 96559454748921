import React , {Fragment}from "react";
// import React , {Fragment, useMemo}from "react";
import {Image} from "react-bootstrap";
import BaseComponent from "../utils/BaseComponent";
// import {CopyToClipboard} from 'react-copy-to-clipboard';
// import clipboard_image1 from "../assets/images/demo-images/demo1.jpg"
import clipboard_image2 from "../assets/images/demo-images/demo2.jpg"
import {isMobile, isAndroid, isIOS} from 'react-device-detect';

// import {useDropzone} from 'react-dropzone'


// class AtomicImage  extends BaseComponent {
//     constructor(props) {
//          super(props);
//      }

//      render(){   
//         const {src} = this.props;
//         return (<img onLoad={this.onImgLoad} src={src}/>);
//      }
//  }

class Test extends BaseComponent {
    constructor() {
        super();
        this.state = {
            toggle: true,
            // image: "",
            image: ""
        };
        
    }

    componentDidMount(){
        if (isMobile && isAndroid) {
            console.log("Android");
            // window.location.replace('https://play.google.com/store/apps/details?id=ai.tagwag.caption');
        }
        if (isMobile && isIOS) {
            console.log("iOS");
            // window.location.replace('https://itunes.apple.com/tt/app/tagwag-captions-for-instagram/id1453977874');
        }
    }

    onImgLoad({target:img}) {
        if (img.offsetHeight >= img.offsetWidth){
            // console.log("portrate");
        }
        else{
            // console.log("landscape");
        }
    }

    outer = e =>{
        e.stopPropagation();
        console.log('outer div');
    };
    
    middle = e =>{
        /* e.stopPropagation(); */
        console.log('middle div');
    };
    
    inner = e =>{
        e.stopPropagation()
        console.log('inner div');
    };

    handleToggle= () => {
        let toggle = this.state.toggle;
        this.setState({
            toggle: !toggle
        });
    }

    handleUploadClick = (file) => {
        console.log(file);
        this.setState({
            image: URL.createObjectURL(file)
        });
    };



    render() {
        // const outer = {
        //     "padding": "20px",
        //     "margin": "20px",
        //     "background": "blue"
        // };

        // const middle = {
        //     "padding": "20px",
        //     "margin": "20px",
        //     "background": "green"
        // };

        // const inner = {
        //     "padding": "20px",
        //     "margin": "20px",
        //     "background": "grey"
        // };



        // const baseStyle = {
        //     width: 200,
        //     height: 200,
        //     borderWidth: 2,
        //     borderColor: '#666',
        //     borderStyle: 'dashed',
        //     borderRadius: 5,
        //     opacity: 0.5
        //   }
          
        // const activeStyle = {
        //     borderStyle: 'solid',
        //     borderColor: '#6c6',
        //     backgroundColor: '#eee'
        // }
          
        // const acceptStyle = {
        //     borderStyle: 'solid',
        //     borderColor: '#00e676'
        // }
          
        // const rejectStyle = {
        //     borderStyle: 'solid',
        //     borderColor: '#ff1744'
        // }

        // function FileUpload(props) {
        //     const {
        //         // acceptedFiles, 
        //         // rejectedFiles,
        //         getRootProps, 
        //         getInputProps,
        //         isDragActive,
        //         isDragAccept,
        //         isDragReject,
        //         open
        //     } = useDropzone({
        //         accept: 'image/*',
        //         multiple: false,
        //         onDrop: acceptedFiles => {
        //             if(acceptedFiles.length > 0){
        //                 console.log(acceptedFiles);
        //                 props.handleUploadClick(acceptedFiles[0]);
        //             }
        //         }
        //     })

        //     const style = useMemo(() => ({
        //         ...baseStyle,
        //         ...(isDragActive ? activeStyle : {}),
        //         ...(isDragAccept ? acceptStyle : {}),
        //         ...(isDragReject ? rejectStyle : {})
        //       }), [
        //         isDragActive,
        //         isDragReject
        //     ])

        //     const rootProps = getRootProps({
        //         // Disable click and keydown behavior
        //         onClick: event => event.stopPropagation(),
        //         onKeyDown: event => {
        //           if (event.keyCode === 32 || event.keyCode === 13) {
        //             event.stopPropagation()
        //           }
        //         }
        //     })
            
          
        //     return (
        //         <div {...rootProps} style={style}>
        //             <input {...getInputProps()} />
        //             <button type="button" onClick={open}>
        //                 Open File Dialog
        //             </button>
        //         </div>
        //     )
        // }
          
        return (
            <Fragment>

            
            {/* <FileUpload handleUploadClick={this.handleUploadClick}/> */}

            <Image onLoad={this.onImgLoad}  src={clipboard_image2} alt="abc" />
            {/* <AtomicImage src={clipboard_image} alt="abc" /> */}

            {/* <div className={this.state.toggle ? "trigger" : "trigger clicked"} onClick={this.handleToggle}>
                <div className="box"></div>
            </div> */}
            
            {/*  <div style={outer} onClick={this.outer}>
             outer
                 <CopyToClipboard 
                 text={ this.state.hashtag }
                 // onCopy={ this.inner }
                 >
                     <div 
                         style={inner}
                         onClick={this.inner}
                     >
                         inner
                     </div>
                 </CopyToClipboard>
             </div> 
             <div style={outer} onClick={ this.outer }>
                  outer
                  <div style={middle} onClick={ this.middle }>
                  middle
                      <div style={inner} onClick={ this.inner }>
                      inner
                      </div>
                  </div>
             </div> */}

            </Fragment>
        );
    }
}

export default Test;
