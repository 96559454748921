import React from "react";
import { Modal } from "react-bootstrap";
import close from '../../assets/images/icons/modalClose.svg'

const ImageModal = (props) => {
    return (
        <>
            <Modal
                show={props.show}
                onHide={props.onClose}
                className="image-modal"
            >
                <div className="modal-bg-image">
                    <img className="result-image" src={props.profile} alt="copy success" />
                    <img className="close-modal" src={close} onClick={props.onClose} alt="copy success" />
                </div>
            </Modal>
        </>
    );
}
//}

export default ImageModal;
