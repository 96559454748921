import React, { Component, Fragment } from 'react';
import Header from './Header';
import { Image } from "react-bootstrap";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import drag_image from '../../assets/images/camera.svg';
import app_store from "../../assets/images/mob/appstore.svg";
import google_play from "../../assets/images/mob/playstore.svg";
import copy_icon from "../../assets/images/mob/copy.svg";
import share from '../../assets/images/mob/share.svg'
import GAUtils from "../../utils/GAUtils";
import { useDropzone } from 'react-dropzone'
import { CopyModal, ErrorModal, ImageModal } from '../modals';

export default class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isScrolling: false,
			copyModal: false,
			imageModal: false,
			showItems: 2,
			newLine: "\n\n",
		}
	}

	componentDidMount = () => {
		//window.addEventListener("popstate", this.back);
		window.addEventListener('scroll', this.handleScroll);
		//console.log('window.scrollTop', window.scroll);
		window.onpopstate = () => {
			//window.location.replace('/');
			//this.props.history.goBack();
			window.history.back();
			//history.go(-1).
			//this.history.go();
		}
	}

    /* back = () => {
        console.log('Back');
        window.location.assign('http://tagwag.ai/hashtags/');
    } */

	handleScroll = (event) => {
		if (window.scrollY > 180) {
			//console.log('window.scrollY', window.scrollY);
			this.setState({
				isScrolling: true
			});
		} else {
			this.setState({
				isScrolling: false
			});
		}
	}

	openCloseCopyModal = (e) => {
		//e.stopPropagation();
		this.setState({
			copyModal: !this.state.copyModal,
		});
	};

	share = (caption, hashtag) => {
		console.log('Share', this.props.profileImage);
		console.log('Image', caption + this.state.newLine + hashtag);

		if (navigator.share) {
			if (navigator.running === true) {
				return;
			}
			navigator.running = true;
			navigator.share({
				title: 'TagWag',
				text: caption + this.state.newLine + hashtag,
			}).then(() => {
				console.log('Thanks for sharing!');
				navigator.running = false;
			})
				.catch(err => {
					navigator.running = false;
					console.log(`Couldn't share because of`, err.message);
				});
		} else {
			console.log('web share not supported');
		}
	}

	viewMore = () => {
		//GAUtils.handleClickEvent("View More", "View More Clicked from Mobile");
		console.log('View More');
		if (navigator.userAgent.match(/Android/i)) {
			console.log('Ios');
			window.location.replace('https://play.google.com/store/apps/details?id=ai.tagwag.caption&referrer=utm_source%3Dtagwag_website%26utm_medium%3DDownloadFromFooterPlayStore');
		} else if (navigator.userAgent.match(/iPhone|iPad/i)) {
			console.log('Android');
			window.location.replace('https://itunes.apple.com/tt/app/tagwag-captions-for-instagram/id1453977874');
		} else {
			console.log('Desktop');
		}
	}

	openImageModal = () => {
		console.log('Open Image Modal');
		this.setState({
			imageModal: !this.state.imageModal
		});
	}

	render() {
		function FileUpload(props) {
			const {
				getRootProps,
				getInputProps
			} = useDropzone({
				accept: 'image/*',
				multiple: false,
				onDrop: acceptedFiles => {
					if (acceptedFiles.length > 0) {
						props.handleUploadClick(acceptedFiles[0]);
					}
				}
			})
			return (
				<div {...getRootProps()} className="file-upload-area">
					<input {...getInputProps()} />
					<p></p>
				</div>
			)
		}

		return (
			<Fragment>
				{
					this.state.copyModal &&
					<CopyModal
						show={this.state.copyModal}
						onClose={this.openCloseCopyModal}
					/>
				}
				{
					this.props.errorModal &&
					<ErrorModal
						show={this.props.errorModal}
						onClose={this.props.closeErrorModal}
					/>
				}
				{
					this.state.imageModal &&
					<ImageModal
						show={this.state.imageModal}
						onClose={this.openImageModal}
						profile={this.props.profileImage}
					/>
				}

				<div className="mobile-wrapper">
					<Header toggle={this.props.isLandingPage} scrolling={this.state.isScrolling} />
					{
						this.props.isLandingPage ?
							this.props.profileImageLoading ?
								<div className="ripple-section">
									<span className="center-image solid-border">
										{console.log('Profile Image', this.props.profileImage)}
										<Image src={this.props.profileImage}
											onLoad={this.props.onImgLoad}
											className={this.props.isImageLandscape ? "" : "portrait"}
										/>
									</span>
									<span className="bottom-text-content load">
										<span className="bold-text">Sit tight</span>
										<span className="normal-text">We are finding hashtag & captions for your image</span>
										{/* <span className="normal-text">& captions for your image</span> */}
									</span>
									<span className="ripple r1"></span>
									<span className="ripple r2"></span>
									<span className="ripple r3"></span>
									<span className="ripple r4"></span>
									<span className="ripple r5"></span>
									<span className="ripple r6"></span>
								</div>
								:
								//First Time Image Image Upload
								<div className="ripple-section">
									<span className="center-image dashed-border">
										{/* <Image src={drag_image} webP={dragCamera} /> */}
										<img src={drag_image} alt="Camera icon" />
										{/* <picture>
                                            <source srcset={dragCamera} type="image/webp" />
                                            <img src={drag_image} alt="logo" />
                                        </picture> */}
									</span>
									<span className="bottom-text-content">
										CLICK TO UPLOAD IMAGE
                                    </span>
									<span className="ripple r1"></span>
									<span className="ripple r2"></span>
									<span className="ripple r3"></span>
									<span className="ripple r4"></span>
									<span className="ripple r5"></span>
									<span className="ripple r6"></span>
									<FileUpload
										handleUploadClick={(file) => {
											GAUtils.handleClickEvent("Image Upload", "Upload Image")
											this.props.handleUploadClick(file)
										}}
									//handleDropingTrue={this.handleDropingTrue}
									/>
								</div>
							:
							this.props.profileImageLoading ?
								//Image Loading
								<div className="ripple-section">
									<span className="center-image solid-border">
										{console.log('Profile Image', this.props.profileImage)}
										<Image src={this.props.profileImage}
											onLoad={this.props.onImgLoad}
											className={this.props.isImageLandscape ? "" : "portrait"}
										/>
									</span>
									<span className="bottom-text-content">
										<span className="bold-text">Sit tight</span>
										<span className="normal-text">We are finding hashtag & captions for your image</span>
									</span>
									<span className="ripple r1"></span>
									<span className="ripple r2"></span>
									<span className="ripple r3"></span>
									<span className="ripple r4"></span>
									<span className="ripple r5"></span>
									<span className="ripple r6"></span>
									{/* <FileUpload
                                        handleUploadClick={(file) => {
                                            GAUtils.handleClickEvent("Image Upload", "Upload Image")
                                            this.props.handleUploadClick(file)
                                        }}
                                    //handleDropingTrue={this.handleDropingTrue}
                                    /> */}
								</div>
								:
								<>
									{/* Final Result with output */}
									{console.log('Result')}
									<div className="output">
										<div className="output-image">
											<Image
												src={this.props.profileImage}
												alt="clipboard"
												className="clipboard-image"
												onClick={this.openImageModal}

											/>
										</div>
										<div className="output-content">
											<h2 className={`heading ${this.state.isScrolling ? "no-heading" : ""}`}>
												Hashtags & Captions
                                            </h2>
											{
												this.props.captions.slice(0, this.state.showItems).map((caption, key) => (
													<p className="inner-section border" key={key}>
														<h3 className="captions">{caption}</h3>

														<p className="hashtags">
															{this.props.hashtag}
														</p>
														<hr />
														<span className="copy-img">
															<Image
																src={share}
																alt="share"
																onClick={() => this.share(caption, this.props.hashtag)}
															/>
															<CopyToClipboard
																text={caption + this.state.newLine + this.props.hashtag}
															>
																<Image
																	src={copy_icon}
																	alt="Copy icon"
																	//className="mg-l-1"
																	onClick={(e) => {
																		GAUtils.handleClickEvent("copy", "captions copied from outside modal", this.props.imageId, `position:${key + 1}`)
																		this.openCloseCopyModal()
																	}}
																/>
															</CopyToClipboard>
														</span>
													</p>
												))}

											<button type="button" className="view-more" onClick={(e) => {
												GAUtils.handleClickEvent("View", "View More Clicked");
												this.viewMore()
											}} /* onClick={this.viewMore} */>More</button>
										</div>
									</div>
								</>

					}

					{
						this.props.isLandingPage ?
							<>
								<div className="mobile-content-bottom">
									<div>
										<div className="mobile-content-section">
											<h4 className="normal-text">Find trending </h4>
											<h4 className="highlight-text">Hashtags & Captions</h4>
											<h4 className="normal-text">for your image</h4>
										</div>
										<div className="downloading-section">
											<a
												className="playstore"
												href="https://play.google.com/store/apps/details?id=ai.tagwag.caption&referrer=utm_source%3Dtagwag_website%26utm_medium%3DDownloadFromFooterPlayStore"
												rel="noopener noreferrer" target="_blank"
											>
												<Image onClick={() => GAUtils.handleClickEvent("Download", "Playstore Button Clicked From Inside")} src={google_play} className="download-button" alt="play store" />
											</a>

											<a
												className="appstore"
												href="https://apps.apple.com/tt/app/tagwag-captions-for-instagram/id1453977874?utm_source=tagwag_home_page_website&utm_medium=DownloadAppFromIcon&utm_campaign=DownloadApp"
												target="_blank" rel="noopener noreferrer"
											>
												<Image onClick={() => GAUtils.handleClickEvent("Download", "Appstore Button Clicked From Inside")} src={app_store} className="download-button app-store-button" alt="app store" />
											</a>
										</div>
									</div>
								</div>
							</>
							:
							console.log('Hide')
					}

				</div>
			</Fragment>
		)
	}
}
